<template>
  <div class="modal-backdrop" v-on:click.self.prevent="backdropClose ? close() : false">
    <div class="modal-wrapper">
      <header v-bind:class="{ 'modal-header': defaultHeader }">
        <slot name="header"></slot>
      </header>
      <section class="modal-body">
        <slot name="body">{{ $t('defaultBody') }}</slot>
      </section>
      <footer v-bind:class="{ 'modal-footer': defaultFooter }">
        <slot name="footer">
          <button v-if="defaultFooter" type="button" class="button is-primary is-outlined is-medium" @click="close">
            {{ $t('close') }}
          </button>
        </slot>
      </footer>
    </div>
  </div>
</template>
<style lang="scss">
@import 'assets/sass/_variables';

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}

.modal-wrapper {
  padding: 10px;
  height: 80%;
  min-width: 90%;
  max-width: 90%;
  left: 5%;
  background: $background;
  box-shadow: 2px 2px 20px 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 80%;
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: flex;
}

.modal-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #eeeeee;
  color: $gt-blue-dark;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
  min-height: 60px;

  button {
    top: 4px !important;
  }
}

.modal-body {
  position: relative;
  padding: 15px 10px;
  overflow-y: auto;
  flex-grow: 2;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: $mobile-portrait) {
  .modal-backdrop {
    align-items: stretch;
    padding-top: 60px;
  }

  .modal-wrapper {
    padding: 0px;
    max-width: 100%;
    width: 100%;
    left: 0;
    height: 100%;
    max-height: 100%;

    .modal-body {
      flex-grow: 3;

      .media-content {
        min-width: 100%;
      }
    }
  }

  .modal-body {
    article {
      flex-flow: column nowrap;

      .media-left {
        margin-bottom: 50px;
      }
    }
  }
}
</style>

<script>
export default {
  name: 'modal',
  inject: ['$validator'],
  props: ['defaultHeader', 'defaultFooter', 'backdropClose'],
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
