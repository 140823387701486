var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{ref:"mainnav",staticClass:"navbar is-transparent",class:{ mobile: _vm.mobileMenuOpen },attrs:{"id":"mainnav"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar-menu"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"navbar-item is-expanded is-paddingless ml-20"},[_c('artist-search',{attrs:{"refs":"artistSearch","name":"desktopSearch","searchMaximized":_vm.searchMaximized},on:{"blur":_vm.exitSearch}})],1),_vm._v(" "),_c('div',{staticClass:"navbar-end"},[(!_vm.isAuthenticated)?_c('nuxt-link',{staticClass:"navbar-item is-tab",attrs:{"to":_vm.localePath({ name: 'portal-signup' }),"id":"signup"}},[_vm._v("\n          "+_vm._s(_vm.$t('signup'))+"\n        ")]):_vm._e(),_vm._v(" "),(
            !_vm.isAuthenticated &&
            _vm.$route &&
            _vm.$route.name &&
            !_vm.$route.name.startsWith('portal-login___')
          )?_c('span',{staticClass:"navbar-item is-tab is-link",attrs:{"id":"login"},on:{"click":_vm.showModalLogin}},[_vm._v("\n          "+_vm._s(_vm.$t('login'))+"\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.isAuthenticated &&
            _vm.followship &&
            _vm.followship.users &&
            _vm.followship.users.length &&
            _vm.showFollowshipMenu
          )?_c('div',{ref:"dropdown",staticClass:"dropdown navbar-item is-tab is-hidden-touch"},[_c('div',{staticClass:"dropdown-trigger"},[_c('button',{staticClass:"button",attrs:{"aria-haspopup":"true","aria-controls":"dropdown-followed"},on:{"click":function($event){$event.preventDefault();return _vm.dropdownToggle.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('followedArtists')))]),_vm._v(" "),_vm._m(1)])]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu",attrs:{"id":"dropdown-followed","role":"menu"},on:{"!click":function($event){return _vm.dropdownToggle.apply(null, arguments)}}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"dropdown-content"},[_c('portal-target',{attrs:{"name":"desktopFollowship"}})],1)])])]):_vm._e(),_vm._v(" "),(_vm.isAuthenticated)?_c('a',{staticClass:"navbar-item is-tab",attrs:{"href":"/myprofile","id":"myProfileLink"}},[_vm._v(_vm._s(_vm.$t('hi'))+" "+_vm._s(_vm.user.nickname))]):_vm._e(),_vm._v(" "),(_vm.isAuthenticated)?_c('a',{staticClass:"navbar-item is-tab",attrs:{"id":"logoutLink"},on:{"click":function($event){$event.preventDefault();return _vm.doLogout.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('logOut')))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"language-container",class:{
            mobile: _vm.mobileMenuOpen
          }},_vm._l((_vm.$i18n.locales),function(locale){return _c('div',{key:locale.code,staticClass:"is-link is-tab",class:{
              'navbar-item': !_vm.mobileMenuOpen,
              'mobile-language-link': _vm.mobileMenuOpen
            },on:{"click":function($event){$event.preventDefault();return _vm.switchLanguage(locale)}}},[_vm._v("\n            "+_vm._s(locale.code.toUpperCase())+"\n          ")])}),0),_vm._v(" "),_c('div',{ref:"burger",staticClass:"burger",class:{ active: _vm.mobileMenuOpen },attrs:{"id":"burger"},on:{"click":_vm.toggleMobileMenu}},[_c('span',{staticClass:"upper"}),_vm._v(" "),_c('span',{staticClass:"middle"}),_vm._v(" "),_c('span',{staticClass:"bottom"})])],1)])]),_vm._v(" "),_c('mobileNav',{ref:"mobileNav",class:{ open: _vm.mobileMenuOpen },on:{"logoperation":_vm.handleLogOperation}}),_vm._v(" "),_c('div',{class:{ 'dropdown-backdrop': _vm.followBoxOpened },on:{"!click":function($event){return _vm.dropdownToggle.apply(null, arguments)}}}),_vm._v(" "),_c('div',{class:{ 'search-backdrop': _vm.searchMaximized && !_vm.mobileMenuOpen },on:{"!click":function($event){return _vm.exitSearch.apply(null, arguments)}}}),_vm._v(" "),_c('portal',{attrs:{"to":_vm.followshipTarget}},[_c('followBox')],1),_vm._v(" "),(!_vm.isAuthenticated && _vm.showLogin)?_c('section',{staticClass:"section"},[_c('modal',{attrs:{"backdropClose":!_vm.socialRegistration,"defaultHeader":"true","defaultFooter":!_vm.socialRegistration},on:{"close":_vm.closeLoginModal}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.modalLoginTitle))]),_vm._v(" "),_c('div',{staticClass:"reward-grow",attrs:{"slot":"body"},slot:"body"},[_c('div',{staticClass:"is-overlay"},[_c('section',{staticClass:"scale-hack"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"modal-card animated slideInDown login-modal pt-10 pl-10 pr-10"},[_c('socialLogin',{attrs:{"divider":true},on:{"registration":_vm.setSocialRegistration,"logged":_vm.closeLoginModal}}),_vm._v(" "),(!_vm.socialRegistration)?_c('div',[_c('login',{attrs:{"params":{
                      isWizard: true
                    }},on:{"viewChanged":_vm.setModalLoginTitle,"logged":_vm.closeLoginModal}})],1):_vm._e()],1)])])])])])],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar-start"},[_c('a',{staticClass:"nav-item nav-brand",attrs:{"href":"/"}},[_c('div',{staticClass:"logo-wrapper"},[_c('img',{attrs:{"src":"/img/logo_getnext_25_11_18_3.svg"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fas fa-angle-down",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }