import Vuex from 'vuex'
import Api from '~/mixins/api'
import Common from '~/mixins/common'

const createStore = () => {
  return new Vuex.Store({
    state: {
      user: {},
      artist: {},
      host: null,
      dashboard: {},
      followship: {},
      mobileMenuOpen: false,
      isAuthenticated: false,
      missing: false,
      selectedPurchasables: 'rewards',
      settings: {},
      returnRouteObject: false,
      redirectPath: false,
      postFilter: {
        tags: [],
        product: [],
        reward: [],
        contents: [],
        months: [],
        public: false,
        limitToFollowers: false
      },
      profilePostsLoaded: false,
      profilePostsLoading: false,
      postTags: [],
      hasMigrationCookie: false
    },

    mutations: {
      setUser(state, user) {
        if (user) {
          state.isAuthenticated = true
          if (state.user.singlePost) {
            user.singlePost = Object.assign({}, state.user.singlePost)
          }
          state.user = user
        }
      },

      loadProfile(state, data) {
        if (!data) {
          return
        }
        if (data.artist) {
          const artistChanged = state.artist.urlAlias !== data.artist.urlAlias
          state.artist = data.artist
          state.postFilter = artistChanged
            ? { product: [], reward: [], months: [], contents: [], tags: [], public: false, limitToFollowers: false }
            : state.postFilter
          state.profilePostsLoaded = artistChanged ? false : state.profilePostsLoaded
        }
        if (data.settings) {
          state.settings = Object.assign({}, state.settings, data.settings)
        }
      },

      logout(state) {
        state.isAuthenticated = false
        state.hasMigrationCookie = false
        state.user = {}
      },

      /**
       * Appends one or more comments to an existing parent in the store (store action)
       * data = {
       *  parent: post
       *  comments: comments
       * }
       * Stored comment objects will be enriched by the parent's ancestors' ids and the parent's id.
       *
       * @param {*} state - vuex state
       * @param {*} data - the data
       */
      appendCommunityComments(state, data) {
        // Handling root comment
        if (!data.parent._id) {
          return state.artist.communityComments.unshift(...data.comments)
        }

        data.parent.ancestors = data.parent.ancestors || []
        const ancestors = [...data.parent.ancestors, data.parent._id]
        data.comments.forEach(comment => {
          comment.ancestors = ancestors
        })

        const copiedComments = Common.methods.deepClone(state.artist.communityComments)

        let current = { children: copiedComments }

        for (const id of ancestors) {
          current = current.children.find(c => c._id === id)
        }

        current.children = current.children || []
        current.children.push(...data.comments)

        current.childrenCount = Math.max(current.childrenCount, current.children.length)

        state.artist = Object.assign({}, state.artist, { communityComments: copiedComments })
      },

      deleteCommunityComment(state, post) {
        const comments = {
          children: state.artist.communityComments
        }
        let current = comments
        if (post.ancestors.length > 0) {
          for (const id of post.ancestors) {
            current = current.children.find(c => c._id === id)
          }
        } else {
          current = current.children.find(c => c._id === post._id)
        }
        if (current) {
          current.status = 'deleted'
        }
      },
      addCommunityComments(state, comments) {
        state.artist.communityComments = [
          ...state.artist.communityComments,
          ...comments
        ]
      },

      setCommunityCommentPinned(state, data) {
        const idx = state.artist.communityComments.findIndex((c) => c._id === data.commentId)
        if (idx > -1) {
          const copiedComments = Common.methods.deepClone(state.artist.communityComments)
          copiedComments[idx].pinned = data.enabled
          state.artist = Object.assign({}, state.artist, { communityComments: copiedComments })
        }
      },

      setSinglePost(state, post) {
        state.user = Object.assign({}, state.user, { singlePost: post })
      },

      clearSinglePost(state) {
        state.user = Object.assign({}, state.user, { singlePost: null })
      },

      setArtistPosts(state, posts) {
        const normalPosts = []
        const stickyPosts = []
        const postTags = []

        for (const post of posts) {
          if (post.tags) {
            for (const tag of post.tags) {
              if (postTags.findIndex((t) => t.id === tag.id) < 0) {
                postTags.push({ id: tag.id, name: tag.name, slug: tag.slug })
              }
            }
          }
          if (!post.sticky) {
            normalPosts.push(post)
          } else {
            stickyPosts.push(post)
          }
        }

        if (stickyPosts.length && stickyPosts[0].stickyOrder) {
          stickyPosts.sort((a, b) => {
            if (a.stickyOrder === b.stickyOrder) return 0
            return a.stickyOrder > b.stickyOrder ? 1 : -1
          })
        }

        state.postTags = postTags
        state.artist.posts = normalPosts
        state.artist.stickyPosts = stickyPosts
      },

      refreshFollowerPosts(state, posts) {
        const updatedSticky = [...state.artist.stickyPosts]
        const updatedNormal = [...state.artist.posts]
        for (const post of posts.filter(p => p.sticky)) {
          const idx = updatedSticky.findIndex((p) => p.id === post.id)
          if (idx > -1) updatedSticky[idx] = post
        }
        for (const post of posts.filter(p => !p.sticky)) {
          const idx = updatedNormal.findIndex((p) => p.id === post.id)
          if (idx > -1) updatedNormal[idx] = post
        }
        state.artist = Object.assign({}, state.artist, { stickyPosts: [...updatedSticky], posts: [...updatedNormal] })
      },

      refreshPollData(state, data) {
        if (!state.user.singlePost) return
        if (
          state.user.singlePost.poll &&
          state.user.singlePost.poll._id === data._id
        ) {
          const post = state.artist.posts.find(p => p._id === state.user.singlePost._id)
          if (post) {
            post.poll.hasVoted = data.hasVoted
            post.poll.options = data.options
            post.poll.total = data.total
          }
        }
      },

      setFollowArtist(state, follow) {
        state.artist.followed = follow
        if (follow) {
          state.followship.users.push({
            artist: state.artist,
            user: state.user._id
          })
        } else {
          state.followship.users = state.followship.users.filter(item => item.artist.urlAlias !== state.artist.urlAlias)
        }
      },
      setHost(state, host) {
        state.host = host
      },

      setFollowship(state, followship) {
        state.followship = followship
      },

      setArtistNotification(state, data) {
        const copiedArtist = Common.methods.deepClone(state.artist)
        copiedArtist.notifications = state.artist.notifications || {}
        copiedArtist.notifications[data.type] = data.state
        state.artist = Object.assign({}, state.artist, copiedArtist)
      },

      setInterfaceLanguage(state, data) {
        state.user.preferences.interfaceLanguage = data
      },

      toggleMobileMenuOpen(state) {
        state.mobileMenuOpen = !state.mobileMenuOpen
      },

      toggleSelectedPurchasables(state, data) {
        state.selectedPurchasables = data
      },

      setPostFilter(state, filter) {
        state.postFilter = filter
      },

      setProfilePostsLoaded(state, loaded) {
        state.profilePostsLoaded = loaded
      },

      setProfilePostsLoading(state, loaded) {
        state.profilePostsLoading = loaded
      },

      loadMorePosts(state, posts) {
        if (posts) {
          state.artist.posts.push(...posts)
        }
      },

      updateTagFilter(state, tags) {
        state.postFilter = { ...state.postFilter, tags }
      },

      resetPostFilter(state) {
        state.postFilter = Object.assign({}, {
          tags: [],
          product: [],
          reward: [],
          contents: [],
          months: [],
          public: false,
          limitToFollowers: false
        })
      },

      reloadPurchasables(state, data) {
        if (state.artist.urlAlias === data.urlAlias) {
          const copiedArtist = Common.methods.deepClone(state.artist)
          copiedArtist.rewards = data.purchasables.rewards
          copiedArtist.products = data.purchasables.products
          state.artist = Object.assign({}, state.artist, copiedArtist)
        }
      },

      storeReturnRouteObject(state, routeObject) {
        state.returnRouteObject = routeObject
      },

      removeReturnRouteObject(state) {
        state.returnRouteObject = false
      },

      storeRedirectPath(state, redirectPath) {
        state.redirectPath = redirectPath
      },

      removeStoreRedirectPath(state) {
        state.redirectPath = false
      },

      hasMigrationCookie(state, value) {
        state.hasMigrationCookie = value
      }
    },
    actions: {
      /**
       * this runs on server side only
       * browser --> server --> api --> vuex --> browser
       */
      async nuxtServerInit({ dispatch }, context) {
        context.store.commit('setHost', context.req.headers.host)

        // only request when defined cookie is present
        // to prevent useless request that will reult in 403 anyway
        if (context.app.$cookies.get('gtc_s')) {
          try {
            /**
             * parallel...
             */
            const [data, followership] = await Promise.all([
              Api.methods.apiGet(
                '/api/private/user',
                null,
                context.req.headers.cookie
              ),
              Api.methods.apiGet(
                '/api/private/followship',
                null,
                context.req.headers.cookie
              )
            ])
            if (data && data.user) {
              context.store.commit('setUser', data.user)
              const hostSegments = context.req.headers.host.split('.')
              if (hostSegments.length) {
                const code = hostSegments[0] === 'getnext' ? 'en' : hostSegments[0]
                if (code !== data.user.preferences.interfaceLanguage) {
                  try {
                    const result = await Api.methods.apiPost(
                      '/api/private/preferences/interface-language', {
                        interfaceLanguage: code
                      }, context.req.headers.cookie
                    )
                    if (result.ok) {
                      context.store.commit('setInterfaceLanguage', code)
                    }
                  } catch (e) {
                    console.error('Error setting interface language: ', e)
                  }
                }
              }
            }
            if (followership) {
              context.store.commit('setFollowship', followership)
            }
          } catch (e) {
            console.error('Caught API-Error', e.toString())
          }
        }
      },

      logout(context) {
        context.commit('logout')
        context.commit('hasMigrationCookie', false)
        this.$localForage.posts.clear()
      },

      login(context, data) {
        if (!data.user) {
          return false
        }
        context.commit('setUser', data.user)

        if (context.state.redirectPath) {
          const path = context.state.redirectPath
          context.commit('removeStoreRedirectPath')
          window.location.href = path
          return
        } else if (data.user.loginRedirectRoute) {
          // loginRedirectRoute from db (signup/pw reset -> login)
          return this.$router.push(data.user.loginRedirectRoute)
        } else if (
          context.state.returnRouteObject &&
          context.state.returnRouteObject.route
        ) {
          // returnRouteObject from store (visit -> login)
          const returnRouteObject = Object.assign({}, context.state.returnRouteObject)
          context.commit('removeReturnRouteObject')
          if (
            data.user.preferences &&
            data.user.preferences.interfaceLanguage &&
            data.user.preferences.interfaceLanguage !== returnRouteObject.locale
          ) {
            if (process.client && window) {
              const locale = returnRouteObject.locales.filter(l => l.code === data.user.preferences.interfaceLanguage)[0]
              if (locale && returnRouteObject.route.fullPath) {
                window.location.href = `https://${locale.domain}${returnRouteObject.route.fullPath}`
                return
              }
              if (returnRouteObject.route.name) {
                context.dispatch('getFollowship')
                return this.$router.push({
                  name: returnRouteObject.route.name,
                  params: returnRouteObject.route.params,
                  query: returnRouteObject.route.query })
              }
              window.location.replace('/')
            }
          } else {
            context.dispatch('getFollowship')
            return this.$router.push({
              name: returnRouteObject.route.name,
              params: returnRouteObject.route.params,
              query: returnRouteObject.route.query
            })
          }
        }

        if (data.user.artist) {
          this.$router.replace({ path: '/myprofile' })
          window.location.replace('/myprofile')
        } else {
          if (!data.from || !data.from.name) {
            window.location.replace('/')
          } else if (
            data.from.name &&
            (
              data.from.name.startsWith('portal-signup') ||
              data.from.name.startsWith('portal-login')
            )) {
            window.location.replace('/')
          } else {
            context.dispatch('getFollowship')
            this.$router.replace({ path: data.from.fullPath })
          }
        }
      },

      loginPayment(context, data) {
        context.commit('setUser', data.user)
      },

      setMigrationCookie(context, data) {
        context.commit('hasMigrationCookie', true)
      },

      async getSinglePost(context, data) {
        try {
          const response = await Api.methods.apiGet(
            `/api/public/${data.urlAlias}/post/${data.urlSlug}/${data.postId}`
          )
          if (response && response.post) {
            context.commit('setSinglePost', response.post)
            return response.post
          }
        } catch (e) {
          console.error('Caught API-Error', e.toString())
        }
      },

      async getFollowship(context, data) {
        try {
          const followership = await Api.methods.apiGet(
            '/api/private/followship')
          if (followership) {
            context.commit('setFollowship', followership)
          }
        } catch (e) {
          console.error('Caught API-Error', e.toString())
        }
      },

      setFollowship(context, data) {
        context.commit('setFollowship', data)
      },

      setArtistNotification(context, data) {
        context.commit('setArtistNotification', data)
      },

      appendCommunityComments(context, data) {
        context.commit('appendCommunityComments', data)
      },

      deleteCommunityComment(context, id) {
        context.commit('deleteCommunityComment', id)
      },

      setSinglePost(context, data) {
        context.commit('setSinglePost', data)
      },

      clearSinglePost(context) {
        context.commit('clearSinglePost')
      },

      refreshPollData(context, data) {
        context.commit('refreshPollData', data)
      },

      setFollowArtist(context, data) {
        context.commit('setFollowArtist', data)
        context.commit('setArtistNotification', {
          type: 'email',
          state: true
        })
      },

      toggleMobileMenuOpen(context) {
        context.commit('toggleMobileMenuOpen')
      },

      toggleSelectedPurchasables(context, data) {
        context.commit('toggleSelectedPurchasables', data)
      },

      resetPostFilter(context) {
        context.commit('resetPostFilter')
      },

      async loadMorePosts(context, data) {
        const l = context.state.artist.posts.length

        if (data.skip + data.limit <= l) return false

        if (data.skip < l) {
          data.limit = data.skip + data.limit - l
          data.skip = l
        }

        const res = await Api.methods.apiGet(
          `/api/public/${data.urlAlias}/posts/${data.skip}/${data.limit}`,
          { filters: context.state.postFilter }
        )
        if (res && res.success) {
          return context.commit('loadMorePosts', res.posts)
        }

        return false
      },

      async refreshFollowerPosts(context, data) {
        const res = await Api.methods.apiGet(
          `/api/public/${data.urlAlias}/posts/0/9999`,
          { filters: { limitToFollowers: true } }
        )
        if (res && res.posts) {
          context.commit('refreshFollowerPosts', res.posts)
        }
      },

      async refreshSinglePost(context, data) {
        if (!context.state.user.singlePost) {
          console.error('Failed to refresh single post')
          return
        }

        try {
          const response = await Api.methods.apiGet(
            `/api/public/${context.state.artist.urlAlias}/post/${context.state.user.singlePost.titleSlug}/${context.state.user.singlePost.id}`
          )
          if (response && response.post) {
            context.commit('setSinglePost', response.post)
            return response.post
          }
        } catch (e) {
          console.error('Caught API-Error', e.toString())
        }
      },

      async loadAllPosts(context, data) {
        context.commit('setProfilePostsLoading', true)
        const res = await Api.methods.apiGet(
          `/api/public/${data.urlAlias}/posts/0/9999`
        )
        if (res && res.posts) {
          // sort posts into state
          context.commit('setArtistPosts', res.posts)
          // store posts into local db
          await this.$localForage.posts.setItem(data.urlAlias, {
            stickyPosts: context.state.artist.stickyPosts,
            posts: context.state.artist.posts,
            profilePostsLoaded: true
          })
          context.commit('setProfilePostsLoaded', true)
          context.commit('setProfilePostsLoading', false)
        }
      },

      setPostFilter(context, { filter }) {
        context.commit('setPostFilter', filter)
      },

      async loadMoreCommunityComments(context, skip) {
        if (!context.state.artist) return false
        const res = await Api.methods.apiGet(
          `/api/public/${context.state.artist.urlAlias}/community/comment/children`,
          { skip }
        )
        if (res && res.success) {
          context.commit('addCommunityComments', res.children)
        }
      },

      async setCommunityCommentPinned(context, data) {
        const res = await Api.methods.apiPost(
          `/api/private/${context.state.artist.urlAlias}/community/comment/pin`,
          { enabled: data.enabled, commentId: data.commentId }
        )
        if (res && res.success) {
          context.commit('setCommunityCommentPinned', data)
        }
      },

      async reloadPurchasables(context, urlAlias) {
        const res = await Api.methods.apiGet(
          `/api/public/${urlAlias}/purchasables`)

        if (res && res.success) {
          return context.commit('reloadPurchasables', res)
        } else {
          return false
        }
      },

      storeReturnRouteObject(context, routeObject) {
        context.commit('storeReturnRouteObject', routeObject)
      },

      storeRedirectPath(context, redirectPath) {
        context.commit('storeRedirectPath', redirectPath)
      },

      async setInterfaceLanguage(context, code) {
        if (context.state.isAuthenticated) {
          try {
            await Api.methods.apiPost(
              `/api/private/preferences/interface-language`, {
                interfaceLanguage: code
              }
            )
          } catch (e) {
            console.log('Error setting interface language: ', e)
          }
        }
      }
    }
  })
}

export default createStore
